import { useEffect, useState } from "react";

function ShutDown({ setAppState }) {
  const [shutting, setShutting] = useState(true);

  useEffect(()=>{
    setTimeout(()=>{
      setShutting(false);
    }, 2000);
  }, []);

  return (
    <div className="shutdown">
      {shutting ?
        <>
        <img className="shutdownLogo" src="/images/logo_desktop.svg" alt=""></img>
        <p className="shutdownText">Shutting Down</p>
        </>
        :
        <img className="shutdownStart" alt="start button" onClick={()=>setAppState('loading')} src="/images/start.svg"></img>
      }
    </div>
  );
}

export default ShutDown;