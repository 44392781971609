const si = {
  id: 'si',
  title: 'Stupid Inventions',
  repo: 'https://github.com/Nik439/stupid-inventions',
  website: 'https://stupid-inventions.herokuapp.com/',
  images: [
    'stupid-inventions/1.png',
    'stupid-inventions/2.jpg',
    'stupid-inventions/3.png',
    'stupid-inventions/4.png',
    'stupid-inventions/5.png',
    'stupid-inventions/6.png',
    'stupid-inventions/7.png',
  ],
  color: 'white',
  bgcolor: '#007ea7',
  desc: `Stupid Inventions is an online multiplayer game, it was heavily inspired by Patently Stupid, but 
    it’s been reimagined to work completely as a web app. The game requires at least 3 people to be played,
    at the start of the game one of the players has to host a lobby and give the code to the other players,
    who then enter the code and join the lobby, once everyone is in, the game can start. In the first phase the 
    players will be given a prompt with a blank space on it that they must fill in to complete the sentence.
    In the next phase the players will be asked to think about some way of solving the previous problem and to
    then draw it and give it a name. Then comes the presentation stage, here the players will have to, in turns,
    make a presentation to the others about whatever their invention was. Once every player has completed their
    presentation it’s time to vote for their favourites, each player gets 3 votes to distribute among the
    inventions they liked the most. Finally the winner gets revealed and the game is over.`,
  role:`I built the app from the ground up. Developed client and server, designed and implemented
  the underlying API and data modelling.`,
  stack: [
    'React JS',
    'Express',
    'Socket.io',
    'Mongoose',
    'MongoDB'
  ]
}
export default si;