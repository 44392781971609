const bid = {
  id: 'bid',
  title: 'Bid Local',
  repo: 'https://github.com/Snugles/bid-local',
  website: '',
  images: [
    'bid-local/1.jpg',
    'bid-local/2.jpg',
    'bid-local/3.jpg',
    'bid-local/4.jpg',
  ],
  color: '#0f7fa4',
  bgcolor: '#FFD166',
  desc: `Bid Local is an e-commerce mobile app based around auctions in your local area.
  Time to declutter the house? Want to find a bargain which you can pick up locally?
  Register with Bid local, an app that allows you to put your second-hand items on auction,
  and find second hand at bargain prices. It uses old-style live auctions where the highest bidder will
  pay you and pick the items up from your house!`,
  role: `I worked on the Front-End with two other developers in a team of five.
  My responsibilities included: Building part of the client's screens and components,
  writing GraphQL queries to connect to the Back-End implementing the navigation and the authentication
  and designing the logo.`,
  stack: [
    'React Native',
    'Expo',
    'Native Base',
    'Lottie UI',
    'Express',
    'Apollo',
    'GraphQL',
    'JWT',
    'MySQL',
    'PostgreSQL',
    'Sequelize',
    'Docker'
  ]
}
export default bid;