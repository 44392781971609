function File(props) {

  function handleClick () {
    props.openPage(true);
    if (document.getElementById(props.id))
      document.getElementById(props.id).classList.remove('minimize');
  }

  return (
    <div className="fileContainer">
      <div className="file" onClick={()=>handleClick()}>
        <img src={`images/${props.iconName}.png`} className="fileIcon" draggable={false} alt=""></img>
        <span className="fileName">{props.name}</span>
      </div>
    </div>
  );
}

export default File;