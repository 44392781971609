import { useEffect, useState } from "react";
import FolderFile from './FolderFile';
import PortfolioDisplay from './PortfolioDisplay';

import si from '../shared/si';
import bid from '../shared/bid';
import cat from '../shared/cat';

function Portfolio({ setPortfolio }) {
  const [pageSwitch, setPageSwitch] = useState('si');

  useEffect(()=>{
    if(document.querySelector('.front')) document.querySelector('.front').classList.remove('front');
    document.getElementById('portfolio').classList.add('front');
  }, []);

  return (
    <div id="portfolio" className="portfolio" >
      <div className="pageTitle">
        <img className="pageTitleIcon" src="/images/folder_icon.png" alt=""></img>
        <p className="pageTitleText">C:\Desktop\Portfolio Folder\</p>
        <div className="pageButton" onClick={()=>{document.getElementById('portfolio').classList.toggle('minimize')}}><span  className="under" dangerouslySetInnerHTML={{__html: '_'}}></span></div>
        <div className="pageButton" ><span  className="square" dangerouslySetInnerHTML={{__html: '&#9643'}}></span></div>
        <div className="pageButton" onClick={()=>setPortfolio(false)} ><span  className="cross" dangerouslySetInnerHTML={{__html: '&#x2716'}}></span></div>
      </div>
        <div className="optionsLine">
          <span>File</span>
          <span>Edit</span>
          <span>View</span>
          <span>Help</span>
        </div>
      <div className="folderContainer" >
        <div className="selectFile">
          <FolderFile selected={pageSwitch === 'si' ? true : false} title={si.title} id={si.id} openPage={setPageSwitch} />
          <FolderFile selected={pageSwitch === 'cat' ? true : false} title={cat.title} id={cat.id} openPage={setPageSwitch} />
          <FolderFile selected={pageSwitch === 'bid' ? true : false} title={bid.title} id={bid.id} openPage={setPageSwitch} />
        </div>
        <div className="selectDisplay">
          {pageSwitch === 'si' ? <PortfolioDisplay props={si} /> : null}
          {pageSwitch === 'cat' ? <PortfolioDisplay props={cat} /> : null}
          {pageSwitch === 'bid' ? <PortfolioDisplay props={bid} /> : null}
        </div>
      </div>
    </div>
  );
}

export default Portfolio;