import { useState, useEffect } from 'react';
import ReactSlider from 'react-slider';
import StartMenuFile from './StartMenuFile';
import AppBarFile from './AppBarFile';

function AppBar({ pages, setPages }) {
  const [time, setTime] = useState(new Date());
  const [menu, setMenu] = useState(false);

  useEffect(()=>{
    const interval = setInterval(
      () => setTime(new Date()),
      1000
    );
 
    return () => {
      clearInterval(interval);
    }
  }, []);

  function toggleVolumeSlider () {
    document.getElementById('slider').classList.toggle('active');
  }

  function toggleMute () {
    document.getElementById('check').classList.toggle('active');
  }

  return (
    <div className="appBar">

      {menu ?
        <div className="startMenu">
          <div className="startMenuTitleContainer">
            <img className="startMenuTitle" src="/images/nikos_99_logo.svg" alt=""></img>
          </div>
          <div className="startMenuContent">
            <StartMenuFile id="about" name="AboutMe Explorer" iconName="ne_icon" openPage={setPages.about} setMenu={setMenu}/>
            <StartMenuFile id="portfolio" name="Portfolio Folder" iconName="folder_icon" openPage={setPages.portfolio} setMenu={setMenu}/>
            <StartMenuFile id="contact" name="Contact Book" iconName="book_icon" openPage={setPages.contact} setMenu={setMenu}/>
            <div className="menuDivider"></div>
            <div className="startMenuFile" onClick={()=>{setPages.shutdown('shutdown'); setMenu(false)}}>
              <img className="menuFileIcon" src={`images/shut_down_icon.png`} alt=""></img>
              <p className="menuFileName">Shut Down...</p>
            </div>
          </div>
        </div> : null
      }
      
      <div className="startButton" onClick={()=>setMenu(mn => !mn)}>
        <img className="startLogo" alt="start button logo" src="/images/logo_small.png"></img>
        <span className="startText">Start</span>
      </div>
      <div className="divider"></div>

      <div className="appBarFileContainer">
        {pages.about ?
          <AppBarFile id="about" name="AboutMe Explorer" iconName="ne_icon" />
          : null
        }
        {pages.portfolio ?
          <AppBarFile id="portfolio" name="Portfolio Folder" iconName="folder_icon" />
          : null
        }
        {pages.contact ?
          <AppBarFile id="contact" name="Contact Book" iconName="book_icon" />
          : null
        }
      </div>
      
      <div className="divider"></div>

      <div className="utility">
        <img className="utilityVolume" onClick={toggleVolumeSlider} src="/images/volume_icon.png"  alt="volume icon"></img>
        <div className="volumeSlider" id="slider">
          <span>Volume</span>
          <ReactSlider
            className="sliderContainer"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            onChange={(val)=>{console.log(val);}}
            orientation="vertical"
            invert
            min={0}
            max={100}
          />
          <div className="mute">
            <div className="checkbox" onClick={toggleMute}><span id="check" dangerouslySetInnerHTML={{__html: '&#10004'}}></span></div>
            <span>Mute</span>
          </div>
        </div>
        <span className="utilityTime">{time.toLocaleString("en-GB", {hour:"2-digit", minute:"2-digit"})}</span>
      </div>
    </div>
  );
}

export default AppBar;