function StartMenuFile(props) {

  function handleClick () {
    props.openPage(true);
    props.setMenu(false);

    let element = document.getElementById(props.id);
    let front = document.querySelector('.front');

    if (element) {
      if (front)  front.classList.remove('front');
      element.classList.remove('minimize');
      element.classList.add('front');
    }

  }

  return (
    <div className="startMenuFile" onClick={()=>handleClick()}>
      <img className="menuFileIcon" src={`images/${props.iconName}.png`} alt=""></img>
      <p className="menuFileName">{props.name}</p>
    </div>
  );
}

export default StartMenuFile;
