import { useEffect } from "react";

function About({setAbout}) {

  useEffect(()=>{
    if(document.querySelector('.front')) document.querySelector('.front').classList.remove('front');
    document.getElementById('about').classList.add('front');
  }, []);
  
  return (
    <div className="about" id="about">
      <div className="pageTitle">
        <img className="pageTitleIcon" src="/images/ne_file_icon.png" alt=""></img>
        <p className="pageTitleText">about:me - Macrosoft Net Explorer</p>
        <div className="pageButton" onClick={()=>{document.getElementById('portfolio').classList.toggle('minimize')}}><span  className="under" dangerouslySetInnerHTML={{__html: '_'}}></span></div>
        <div className="pageButton" ><span  className="square" dangerouslySetInnerHTML={{__html: '&#9643'}}></span></div>
        <div className="pageButton" onClick={()=>setAbout(false)} ><span  className="cross" dangerouslySetInnerHTML={{__html: '&#x2716'}}></span></div>
      </div>
      <div className="addressLineContainer">
        <div className="addressLine">
          <span>Address</span>
          <div className="address">
            <img className="" src="/images/ne_file_icon.png" alt=""></img>
            <span>about:me</span>
            <span className="addressArrow" dangerouslySetInnerHTML={{__html: '&#x25BC'}}></span>
          </div>
          <span>Go</span>
        </div>
      </div>
      <div className="pageContainer" >
        <header className="pageHeader">
          <h2>Welcome to my web site</h2>
          <h1 className="glitch">Nikos Cocci</h1>
          <h2>Full Stack Web Developer</h2>
        </header>

        <div className="pageContent" style={{backgroundImage: 'url(/images/about/lamp.gif)'}}>
          <div className="ufoContainer">
            <img className="pageUfo" src="/images/about/ufo.gif" alt=""></img>
          </div>
          <div className="pageInfo" style={{backgroundImage: 'url(/images/about/tv.gif), url(/images/about/pc.gif)'}}>
            <img className="infoPic" src="/images/about/pic.png" alt=""></img>
            <p className="infoDescription">{`
              I'm a Full Stack Developer from Italy, i've loved programming my whole life, I'm a driven developer that has
              been studying in this field for several years. I always like to be challenged and embrace new
              technologies.
            `}</p>
          </div>
          <div className="pageTechnologies" style={{backgroundImage: 'url(/images/about/doot.gif), url(/images/about/cube.gif)'}}>
            <div className="techContainer">
              <p className="techTitle">Currently Using</p>
              <div className="techBorder">
                <div className="techContent">
                  <div className="column">
                    <span><img src="/images/about/technologies/react.png" alt=""></img>React JS</span>
                    <span><img src="/images/about/technologies/react.png" alt=""></img>React Native</span>
                    <span><img src="/images/about/technologies/js.png" alt=""></img>Javascript</span>
                    <span><img src="/images/about/technologies/html.png" alt=""></img>HTML</span>
                    <span><img src="/images/about/technologies/css.png" alt=""></img>CSS</span>
                    <span><img src="/images/about/technologies/node.png" alt=""></img>NodeJS</span>
                    <span><img src="/images/about/technologies/git.png" alt=""></img>Git</span>
                  </div>
                  <div className="column">
                    <span><img src="/images/about/technologies/express.png" alt=""></img>Express</span>
                    <span><img src="/images/about/technologies/koa.png" alt=""></img>Koa</span>
                    <span><img src="/images/about/technologies/mongo.png" alt=""></img>MongoDB</span>
                    <span><img src="/images/about/technologies/mongoose.png" alt=""></img>Mongoose</span>
                    <span><img src="/images/about/technologies/postgresql.png" alt=""></img>PostgreSQL</span>
                    <span><img src="/images/about/technologies/graphql.png" alt=""></img>GraphQL</span>
                    <span><img src="/images/about/technologies/socket.png" alt=""></img>Socket.io</span>
                  </div>
                </div>
              </div>
              
            </div>
            <div className="techContainer">
              <p className="techTitle">Previous Experience</p>
              <div className="techBorder">
                <div className="techContent">
                  <div className="column">
                    <span><img src="/images/about/technologies/cs.png" alt=""></img>C#</span>
                    <span><img src="/images/about/technologies/cpp.png" alt=""></img>C++</span>
                  </div>
                  <div className="column">
                    <span><img src="/images/about/technologies/java.png" alt=""></img>Java</span>
                    <span><img src="/images/about/technologies/jquery.png" alt=""></img>jQuery</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="educationContainer">
            <p className="educationTitle">Education History</p>
            <ul>
              <li className="education">
                <p className="educationName">{'<Codeworks/>'}</p>
                <p className="educationCourse">Software Engineering Immersive <span className="educationYear">| 2020</span></p>
              </li>
              <li className="education">
                <p className="educationName">{'<IIS_Volterra_Elia/>'}</p>
                <p className="educationCourse">Computer Science Diploma <span className="educationYear">| 2013-2018</span></p>
              </li>
            </ul>
          </div>
          <div className="hobbiesContainer">
            <p className="hobbiesTitle">Hobbies</p>
            <div className="hobbiesContent">
              <div className="hobbiesColumn">
                <p className="hobbiesColumnTitle">{'>> Pre-Covid'}</p>
                <div className="hobbies">
                  <p>{'> Reading'}</p>
                  <p>{'> MMA / Muay thai'}</p>
                  <p>{'> Camping'}</p>
                </div>
              </div>
              <div className="hobbiesColumn">
                <p className="hobbiesColumnTitle">{'>> Post-Covid'}</p>
                <div className="hobbies">
                  <p>{'> Reading'}</p>
                  <p>{'> Playing videogames'}</p>
                  <p>{'> Watching Netflix'}</p>
                </div>
              </div>
            </div>
          </div>
          <footer></footer>
        </div>
      </div>
    </div>
  );
}

export default About;