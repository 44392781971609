import { useEffect } from "react";

function Contact({ setContact }) {

  useEffect(()=>{
    if(document.querySelector('.front')) document.querySelector('.front').classList.remove('front');
    document.getElementById('contact').classList.add('front');
  }, []);

  return (
    <div className="contact" id="contact">
      <div className="pageTitle">
        <img className="pageTitleIcon" src="/images/book_icon.png" alt=""></img>
        <p className="pageTitleText">Contact Book</p>
        <div className="pageButton" onClick={()=>{document.getElementById('contact').classList.toggle('minimize')}}><span  className="under" dangerouslySetInnerHTML={{__html: '_'}}></span></div>
        <div className="pageButton" ><span  className="square" dangerouslySetInnerHTML={{__html: '&#9643'}}></span></div>
        <div className="pageButton" onClick={()=>setContact(false)} ><span  className="cross" dangerouslySetInnerHTML={{__html: '&#x2716'}}></span></div>
      </div>
        <div className="optionsLine">
          <span>File</span>
          <span>Edit</span>
          <span>View</span>
          <span>Help</span>
        </div>
      <div className="contactContainer">
        <div className="contactContent">
          <table className="contactTable">
            <tbody>
              <tr>
                <td className="tableHeader">Name</td>
                <td className="tableHeader">Address</td>
              </tr>
              <tr>
                <td className="tableCell">Email</td>
                <td className="tableCell">
                  <a href="mailto:coccinikos@gmail.com">
                  coccinikos@gmail.com
                  </a>
                </td>
              </tr>
              <tr>
                <td className="tableCell">Phone</td>
                <td className="tableCell"><a href="tel:+393892075222">(+39)3892075222</a></td>
              </tr>
              <tr>
                <td className="tableCell">GitHub</td>
                <td className="tableCell"><a href="https://github.com/Nik439" rel="noreferrer" target="_blank:">github.com/Nik439</a></td>
              </tr>
              <tr>
                <td className="tableCell">LinkedIn</td>
                <td className="tableCell"><a href="https://www.linkedin.com/in/nikos-cocci/" rel="noreferrer" target="_blank:">linkedin.com/in/nikos-cocci/</a></td>
              </tr>
              <tr>
                <td className="tableCell">CodePen</td>
                <td className="tableCell"><a href="https://www.codepen.io/nik439" rel="noreferrer" target="_blank:">codepen.io/nik439</a></td>
              </tr>
              <tr>
                <td className="tableCell">Codewars</td>
                <td className="tableCell"><a href="https://www.codewars.com/users/Nik439" rel="noreferrer" target="_blank:">codewars.com/users/Nik439</a></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="contactEmpty" style={{backgroundImage: 'url(/images/about/connection.gif)'}}>
        </div>
      </div>
    </div>
  );
}

export default Contact;