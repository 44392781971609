import { useEffect, useState } from "react";

function PortfolioDisplay({ props }) {
  const { title, repo, website, images, color, bgcolor,  desc, stack, role } = props;
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(()=>{
    function onResize () {
      let img = document.getElementById('displayImages');
      img.style.minHeight = img.offsetWidth + 'px';
    }
    
    onResize();
    window.addEventListener('resize', onResize);

    return ()=>{
      window.removeEventListener('resize', onResize);
    }
  }, []);

  return (
    <div className="portfolioDisplay" style={{backgroundColor: bgcolor}}>
      <h1 className="displayTitle" style={{color: color}}>{title}</h1>
      <div id="displayImages" className="displayImages" style={{backgroundImage: `url(/images/portfolio/${images[currentImage]})`}}></div>
      <div className="imageButtonsContainer">
        <div className="imageButton"  style={{color: color}} onClick={()=>{setCurrentImage(curr => images[curr-1] ? curr-1 : curr)}} dangerouslySetInnerHTML={{__html: "&#60"}}></div>
        <div className="imageButton"  style={{color: color}} onClick={()=>{setCurrentImage(curr => images[curr+1] ? curr+1 : curr)}} dangerouslySetInnerHTML={{__html: "&#62"}}></div>
      </div>
      <div className="displayWebContainer">
        {repo ?
          <a className="displayWeb" style={{color: color}} href={repo} rel="noreferrer" target="_blank">
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.2347 12.3402L10.2347 8.31621H11.1267L11.1267 7.31621L11.1267 6.50767H12.0292V5.60739H12.9437V4.70193H13.731H14.731V3.80827H35.2801V4.70905H36.2801H37.0916L37.1061 5.60973H37.9927V6.51762H38.8978V7.31989V8.31989H39.798L39.798 22.8167L39.798 22.8198L39.7653 33.2732V33.2763L39.7653 37.3003H38.8733L38.8733 38.3003L38.8733 39.1089H37.9708V40.0092H37.0563V40.9146H36.269H35.269V41.8083H14.7199V40.9075H13.7199H12.9084L12.8939 40.0068H12.0073V39.0989H11.1022V38.2967V37.2967H10.202L10.202 22.82V22.8169L10.2347 12.3434V12.3402Z" stroke={color} strokeWidth="2"/>
              <path d="M17.0332 34.4654V4.06126L13.6588 4.63736L10.3129 8.91474V35.8732L13.6146 34.6533L17.0332 34.4654Z" fill={color}/>
              <path d="M9.30739 37.3083H10.1973V36.408H11.099V35.5025H12.8613V34.6089H35.0952V33.6484H36.9598V32.8004H37.8968L37.9111 31.98H39.2567" stroke={color} strokeWidth="2"/>
              <path d="M17.5074 38.4248L24.4996 38.4248V47.1917L23.5035 47.1917V46.2049H22.5035V45.2001H21.5035V44.2079H20.5001V45.2002H19.5068V46.2049H18.5034V47.1917H17.5074V38.4248Z" fill={color}/>
              <rect x="19.0035" y="6.60693" width="4" height="4" fill={color}/>
              <rect x="19.0035" y="13.6044" width="4" height="4" fill={color}/>
              <rect x="19.0035" y="20.6044" width="4" height="4" fill={color}/>
              <rect x="19.0035" y="27.6044" width="4" height="4" fill={color}/>
            </svg>
          </a>
          : null
        }
        {website ?
          <a className="displayWeb" style={{color: color}} href={website} rel="noreferrer" target="_blank">
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.06361 18.9928V15.9926H6.99239H7.99239V14.9926V12.0001H8.99425H9.99425V11.0001V10.0026H10.9961H11.9961V9.00263V8.00513H15H16V7.00513V5.99576H18.9844H19.9844V4.99576V4.00001L30.0156 4V4.99575V5.99575H31.0156H34V7.00513V8.00513H35H38.0039V9.00262V10.0026H39.0039H40.0058V11.0001V12.0001H41.0058H42.0076V14.9926V15.9926H43.0076H43.9364V18.9928V19.9928H44.9364H46L46 30.0072H44.9364H43.9364V31.0072V34.0074H43.0076H42.0076V35.0074V37.9999H41.0058H40.0058V38.9999V39.9974H39.0039H38.0039V40.9974V41.9949H35H34V42.9949V44.0042H31.0156H30.0156V45.0042V46L19.9844 46V45.0042V44.0042H18.9844H16V42.9949V41.9949H15H11.9961V40.9974V39.9974H10.9961H9.99424V38.9999V37.9999H8.99424H7.99239V35.0074V34.0074H6.99239H6.0636V31.0072V30.0072H5.0636H4L4.00001 19.9928H5.06361H6.06361V18.9928Z" stroke={color} strokeWidth="2"/>
              <line x1="25" y1="45" x2="25" y2="5" stroke={color} strokeWidth="2"/>
              <path d="M28 6H30V10H32V12H34V18H36L36 32H34V38H32V40H30V44H28" stroke={color} strokeWidth="2"/>
              <path d="M9 37H12V35H17.9938V33L32 33V35L38 35V37H41" stroke={color} strokeWidth="2"/>
              <path d="M9 13H12V15H17.9938V17L32 17V15L38 15V13H41" stroke={color} strokeWidth="2"/>
              <path d="M22 6H20V10H18V12H16V18H14L14 32H16V38H18V40H20V44H22" stroke={color} strokeWidth="2"/>
              <line x1="5" y1="25" x2="45" y2="25" stroke={color} strokeWidth="2"/>
            </svg>
          </a> 
          : null
        }
      </div>
      <h2 className="displaySubTitle" style={{color: color}}>Description</h2>
      <p className="displayText" style={{color: color}}>{desc}</p>
      <h2 className="displaySubTitle" style={{color: color}}>My Role</h2>
      <p className="displayText" style={{color: color}}>{role}</p>
      <h2 className="displaySubTitle" style={{color: color}}>Tech Stack</h2>
      <ul className="displayTechStack">
        {
          stack.map((stackEl, index)=>(
            <li key={index} style={{color: color}}>{stackEl}</li>
          ))
        }
      </ul>
    </div>
  );
}

export default PortfolioDisplay;