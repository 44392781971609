import './App.css';
import Main from './components/Main';
import Loading from './components/Loading'
import ShutDown from './components/ShutDown';
import { useState } from 'react';

function App() {
  const [appState, setAppState] = useState('loading');

  
  switch (appState) {
    case 'loading':
      return <Loading setAppState={setAppState} />;
    case 'main':
      return <Main setAppState={setAppState} />;
    case 'shutdown':
      return <ShutDown setAppState={setAppState} />;
    default:
      return <Main/>;
  }
}

export default App;
