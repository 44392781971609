import { useState } from 'react';
import AppBar from './AppBar';
import File from './File';
import About from './About';
import Portfolio from './Portfolio';
import Contact from './Contact';
import Game from './Game';

function Main({ setAppState }) {
  const [about, setAbout] = useState(false);
  const [portfolio, setPortfolio] = useState(false);
  const [contact, setContact] = useState(false);
  const [game, setGame] = useState(false);

  return (
    <div className="main crt">
      <div className="desktop" style={{backgroundImage: "url(/images/logo_desktop.svg)"}}>
        <File id="about" name="AboutMe Explorer" iconName="ne_icon" openPage={setAbout} />
        <File id="portfolio" name="Portfolio Folder" iconName="folder_icon" openPage={setPortfolio} />
        <File id="contact" name="Contact Book" iconName="book_icon" openPage={setContact}/>  
        {/* <File id="contact" name="Game" iconName="book_icon" openPage={setGame}/>   */}
        {about ? <About setAbout={setAbout}/> : null}
        {portfolio ? <Portfolio setPortfolio={setPortfolio}/> : null}
        {contact ? <Contact setContact={setContact}/> : null}
        {/* {game ? <Game setGame={setGame}/> : null} */}
        {/* <iframe src="/game/index.html" height="604" width="804">game not available</iframe> */}
      </div>
      <AppBar pages={{about, portfolio, contact}} setPages={{about: setAbout, portfolio: setPortfolio, contact: setContact, shutdown: setAppState}} />
    </div>
  );
}

export default Main;
