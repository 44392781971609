function AppBarFile(props) {

  function handleClick () {
    let elements = document.querySelectorAll('.'+props.id);
    let front = document.querySelectorAll('.front');
    
    if (front && front[0] === elements[0]) {
      front.forEach(el=>el.classList.remove('front'));
      elements.forEach(el=>el.classList.add('minimize'));
    } else {
      if (front) front.forEach(el=>el.classList.remove('front'));
      elements.forEach(el=>el.classList.remove('minimize'));
      elements.forEach(el=>el.classList.add('front'));
    }
  }

  return (
    <div className="appBarFile" onClick={handleClick}>
      <img className="appBarFileIcon" src={`images/${props.iconName}.png`} alt=""></img>
      <p className="appBarFileName">{props.name}</p>
    </div>
  );
}

export default AppBarFile;
