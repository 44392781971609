const cat = {
  id: 'cat',
  title: 'Catcalls of AMS',
  repo: 'https://github.com/lisannekraal/catcall',
  website: '',
  images: [
    'catcalls/1.jpg',
    'catcalls/2.jpg',
  ],
  color: 'white',
  bgcolor: '#f52559',
  desc: `An application to visualize, report and manage data on catcalling. Made for @CatcallsofAms,
  a local Instagram-based initiative part of a global 'chalk back' movement. In over a 150 cities wordwide,
  young girls are fighting street harassment by literally chalking catcalls on the sidewalk and raise awareness.
  `,
  role:`My responsibilities included: Adding unit tests for the front-end andback-end, making the
  styling responsive, adding authentication formoderators through GraphQL.`,
  stack: [
    'React JS',
    'Jest',
    'Material UI',
    'MapBox API',
    'Express',
    'Apollo',
    'GraphQL',
    'Mongoose',
    'MongoDB'
  ]
}
export default cat;